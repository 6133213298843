.wrapper {
  position: relative;
  width: 90%;
  margin: 25px 10px;
  padding: 15px 0;
  overflow: visible;
}

.label {
  position: absolute;
  padding-top: 10px;
  transform: translateX(-50%);
  font-size: 12px;
  white-space: nowrap;
}
